import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { Image_Path, URL_Start } from '../../../constant';
import axios from 'axios';
import LoadingButton from '../commonComponent/LoadingButton';
import DataTables from '../commonComponent/DataTable/DataTables';
import Select from 'react-select';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import ToolTip from '../commonComponent/ToolTip';
import { Spinner } from 'react-bootstrap';


const DashboardBanners = () => {
    const [bannerPath, setBannerPath] = useState('');
    const [thumbnailPath, setThumbnailPath] = useState('');
    const [virtualcentre, setVirtualCentre] = useState([]);
    const [bannerdetails, setBannerDetails] = useState([]);
    const [stateSelected, setStateSelected] = useState([]);
    const [regionSelected, setRegionSelected] = useState([]);
    const [virtualcentretype, setVirtualCentreType] = useState([]);
    const [districtSelected, setDistrictSelected] = useState([]);
    const [centreSelected, setCentreSelected] = useState([]);
    const [centretypeSelected, setCentreTypeSelected] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [editicon, setEditIcon] = useState(false);
    const [statename, setStateName] = useState('');
    const [regionname, setRegionName] = useState('');
    const [districtname, setDistrictName] = useState('');
    const [centrename, setCentreName] = useState('');
    const [centretype, setCentreType] = useState('');
    const [stardate, setStartDate] = useState('');
    const [endate, setEndDate] = useState('');
    const [locationstatelist, SetLocationStateList] = useState([]);
    const [locationregionlist, SetLocationRegionList] = useState([]);
    const [locationdistrictlist, SetLocationDistrictList] = useState([]);
    const [statebannerref, setStateBannerRef] = useState("");
    const [urlbannerref, setUrlBannerRef] = useState("");
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [clearfunction, setClearFunction] = useState(false);

    let urlbanneref = useRef();
    let startdate = useRef();
    let enddate = useRef();
    useEffect(() => {   
        // const today = new Date();
        // const date = today.setDate(today.getDate() + 7);
        // const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        // const today1 = new Date();
        // const date1 = today1.setDate(today1.getDate());
        // const defaultValue1 = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd
        // setStartDate(defaultValue1);
        // setEndDate(defaultValue);
        GetAllStates();
        GetDashboardBannerDetails();
    }, [])
    useEffect(() => {
        if (stateSelected.length === 0) {
            SetLocationRegionList([]);
            SetLocationDistrictList([]);
            setVirtualCentreType([]);
            setVirtualCentre([]);
            setRegionSelected([]);
            setDistrictSelected([]);
            setCentreTypeSelected([]);
            setCentreSelected([]);
        }
        else {
            SetLocationRegionList([]);
            SetLocationDistrictList([]);
            setVirtualCentreType([]);
            setVirtualCentre([]);
            setRegionSelected([]);
            setDistrictSelected([]);
            setCentreTypeSelected([]);
            setCentreSelected([]);
            GetAllRegions();
        }
    }, [stateSelected])
    useEffect(() => {
        if (regionSelected.length === 0) {
            SetLocationDistrictList([]);
            setVirtualCentreType([]);
            setVirtualCentre([]);
            setDistrictSelected([]);
            setCentreTypeSelected([]);
            setCentreSelected([]);
        }
        else {
            SetLocationDistrictList([]);
            setVirtualCentreType([]);
            setVirtualCentre([]);
            setDistrictSelected([]);
            setCentreTypeSelected([]);
            setCentreSelected([]);
            GetAllDistricts();
        }
    }, [regionSelected])
    useEffect(() => {
        if (districtSelected.length === 0) {
            setVirtualCentreType([]);
            setVirtualCentre([]);
            setCentreTypeSelected([]);
            setCentreSelected([]);
        }
        else {
            setVirtualCentreType([]);
            setVirtualCentre([]);
            setCentreTypeSelected([]);
            setCentreSelected([]);
            GetAllCentreTypeList();
        }
    }, [districtSelected])
    useEffect(() => {
        if (centretypeSelected.length === 0) {
            setVirtualCentre([]);
            setCentreSelected([]);
        }
        else {
            setVirtualCentre([]);
            setCentreSelected([]);
            GetAllCentreList();
        }
    }, [centretypeSelected])
    useEffect(() => {
        if (statebannerref !== "") {
            setStateBannerRef("");
        }
    }, [statebannerref])
    useEffect(() => {
        if (urlbannerref !== "") {
            urlbanneref.current.value = urlbannerref;
            setUrlBannerRef("");
        }
    }, [urlbannerref])
    useEffect(() => {
        if (bannerPath !== '') {
            var element = document.querySelector("input[type='file']")
            element.classList.add("removeValue");
        }
    }, [bannerPath])
    useEffect(() => {
        if (thumbnailPath !== '') {
            var element = document.querySelector("input[type='file']")
            element.classList.add("removeValue");
        }
    }, [thumbnailPath])
    useEffect(() => {
        if (clearfunction == true) { GetDashboardBannerDetails(); setClearFunction(false); }
    }, [clearfunction])
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <span>{crntPageNO + rowIndex + 1}</span>
        </React.Fragment>
    }
    const actionBannerFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Banner == "" ? null :
                        rowData.Banner.substring(rowData.Banner.lastIndexOf('.') + 1) == "pdf" ? <span style={{ fontSize: 24 }}><i class="fa fa-file-pdf-o"></i></span> :
                            <img src={Image_Path + 'DashboardBanners/' + rowData.Banner} width="80"></img>
                }
            </div>
        </React.Fragment>
    }
    const actionThumbnailBannerFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Thumbnail == "" ? null : <img src={Image_Path + 'ThumbnailBanners/' + rowData.Thumbnail} width="80"></img>
                }
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Edit" placement="top" onClick={() => EditBannerDetails(rowData)} linkId="Edit" faName="fa fa-pencil mr-2"></ToolTip>
                <ToolTip title="Delete" placement="top" onClick={() => DeleteBanner(rowData.CentreBannersId)} linkId="Delete" faName="fa fa-trash mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const columns =
        [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
            { dataField: 'DistrictName', text: 'Location', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
            { dataField: 'CentreType', text: 'Partner Type', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
            { dataField: 'CentreName', text: 'Centre Name', editable: false, headerStyle: (colum, colIndex) => { return { width: '22%' }; } },
            { dataField: 'BannerName', text: 'Banner Text', editable: false, headerStyle: (colum, colIndex) => { return { width: '25%' }; } },
            { dataField: 'Thumbnail', text: 'Thumbnail Attachment', editable: false, formatter: actionThumbnailBannerFormatter, headerStyle: (colum, colIndex) => { return { width: '14%' }; } },
            { dataField: 'Banner', text: 'Banner Attachment', editable: false, formatter: actionBannerFormatter, headerStyle: (colum, colIndex) => { return { width: '14%' }; } },
            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
        ];
    const UploadImages = async (e, folderName) => {
        const FileURL = e.target.files[0].name;
        const BreakURL = FileURL.lastIndexOf('.');
        const extension = FileURL.substring(BreakURL + 1);
        if (extension.toLowerCase() === "pdf" || extension.toLowerCase() === "jpg" || extension.toLowerCase() === "jpeg" ||
            extension.toLowerCase() === "png" || extension.toLowerCase() === "gif" || extension.toLowerCase() === "svg") {
            if (folderName.toLowerCase() == "thumbnailbanners" && extension.toLowerCase() === "pdf") {
                SweetAlert.fire("Invalid file type! You can upload only image, please reupload valid file only.");
                return false;
            }
        }
        else {
            SweetAlert.fire("Invalid file type! You can upload only image/pdf, please reupload valid file only.");
            return false;
        }
        //const extension = e.target.files[0].name.split(".");
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', folderName);
        const randomImageId = Math.random();
        var imgName = randomImageId + folderName + "." + extension; //extension[extension.length - 1];
        formData.append('ImageName', imgName);
        let url = URL_Start + 'master/UploadImage/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data" },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (folderName.toLowerCase() === "dashboardbanners") { setBannerPath(Image_Path + "DashboardBanners/" + imgName); }
                if (folderName.toLowerCase() === "thumbnailbanners") { setThumbnailPath(Image_Path + "ThumbnailBanners/" + imgName); }
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload image due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const StateChangeHandler = (options) => {
        var isBreak = "0";
        for (var key in options) {
            if (options[key].value === "0" && isBreak === "0") {
                isBreak = "1";
            }
        }
        if (isBreak === "1") {
            setStateSelected(locationstatelist);
        } else {
            setStateSelected(options);
        }
    }
    const RegionChangeHandler = (options) => {
        var isBreak = "0";
        for (var key in options) {
            if (options[key].value === "0" && isBreak === "0") {
                isBreak = "1";
            }
        }
        if (isBreak === "1") {
            setRegionSelected(locationregionlist);
        } else {
            setRegionSelected(options);
        }
    }
    const DistrictChangeHandler = (options) => {
        var isBreak = "0";
        for (var key in options) {
            if (options[key].value === "0" && isBreak === "0") {
                isBreak = "1";
            }
        }
        if (isBreak === "1") {
            setDistrictSelected(locationdistrictlist);
        } else {
            setDistrictSelected(options);
        }
    }
    const CentreTypeChangeHandler = (options) => {
        var isBreak = "0";
        for (var key in options) {
            if (options[key].value === "0" && isBreak === "0") {
                isBreak = "1";
            }
        }
        if (isBreak === "1") {
            setCentreTypeSelected(virtualcentretype);
        } else {
            setCentreTypeSelected(options);
        }
    }
    const CentreChangeHandler = (options) => {
        var isBreak = "0";
        for (var key in options) {
            if (options[key].value === "0" && isBreak === "0") {
                isBreak = "1";
            }
        }
        if (isBreak === "1") {
            setCentreSelected(virtualcentre);
        } else {
            setCentreSelected(options);
        }
    }
    const GetAllStates = async () => {
        var glblArr = [];
        let url = URL_Start + 'master/getstateslist/1'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    var itmData = data.Data;
                    itmData.map((option) => { return glblArr.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                    SetLocationStateList(glblArr);
                }
                else {
                    SetLocationStateList([]);
                }
            }
            else { SweetAlert.fire({ title: "Error", text: "No record found", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetAllRegions = async () => {
        var glblArr = [];
        let arryRegions = [];
        for (var i in stateSelected) { arryRegions.push({ 'StateId': stateSelected[i].value }); }
        const requestOptions = {
            objTblLocationTypeList: arryRegions
        };
        let url = URL_Start + 'master/getBindLocationRegionDetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    var itmData = data.Data;
                    itmData.map((option) => { return glblArr.push({ 'value': option.RegionId, 'label': option.RegionName, 'disabled': false }); });
                    SetLocationRegionList(glblArr);
                }
                else {
                    SetLocationRegionList([]);
                }
            }
            else { SweetAlert.fire({ title: "Error", text: "No record found", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const GetAllDistricts = async () => {
        var glblArr = [];
        let arryDistrict = [];
        for (var i in regionSelected) { arryDistrict.push({ 'RegionId': regionSelected[i].value }); }
        const requestOptions = {
            objTblLocationTypeList: arryDistrict
        };
        let url = URL_Start + 'master/getBindLocationDistrictDetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    var itmData = data.Data;
                    itmData.map((option) => { return glblArr.push({ 'value': option.DistrictId, 'label': option.DistrictName, 'disabled': false }); });
                    SetLocationDistrictList(glblArr);
                }
                else {
                    SetLocationDistrictList([]);
                }
            }
            else { SweetAlert.fire({ title: "Error", text: "No record found", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const GetAllCentreTypeList = async () => {
        var glblArr = [];
        let url = URL_Start + 'master/getallcentretypelist/'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    var itmData = data.Data;
                    itmData.map((option) => { return glblArr.push({ 'value': option.PartnerTypeId, 'label': option.PartnerType, 'disabled': false }); });
                    setVirtualCentreType(glblArr);
                }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetAllCentreList = async () => {
        var glblArr = [];
        var arrcentre = [];
        let arrycentrelst = [];
        for (var i in centretypeSelected) { arrycentrelst.push({ 'PartnerTypeId': centretypeSelected[i].value }); }
        const requestOptions = {
            objTblPartnerTypeList: arrycentrelst
        };
        let url = URL_Start + 'master/getallownandthirdpartycentres/'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    for (var i in districtSelected) {
                        for (var key in data.Data) {
                            if (districtSelected[i].value === data.Data[key].SAPDistrictId) {
                                arrcentre.push({ 'Id': data.Data[key].Id, 'Value': data.Data[key].Value });
                            }
                        }
                    }
                    var itmData = arrcentre;
                    itmData.map((option) => { return glblArr.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                    setVirtualCentre(glblArr);
                    setCentreSelected(glblArr);
                }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetDashboardBannerDetails = async () => {
        var startdate = "0";
        var enddate = "0";
        var allstate = "";
        var allregion = "";
        var alldistrict = "";
        var allcentreytpe = "";
        var allcentre = "";

        if (stardate == undefined || stardate == null || stardate == "") { startdate = "0"; }
        else { startdate = stardate }
        if (endate == undefined || endate == null || endate == "") { enddate = "0"; }
        else { enddate = endate }

        if (stateSelected.length == 0) { allstate = "0" }
        else {
            for (var key in stateSelected) {
                if (stateSelected.length == parseInt(key + 1)) { allstate += stateSelected[key].value; }
                else { allstate += stateSelected[key].value + ',' }
            }
        }
        if (regionSelected.length == 0) { allregion = "0" }
        else {
            for (var keyy in regionSelected) {
                if (regionSelected.length == parseInt(keyy + 1)) { allregion += regionSelected[keyy].value; }
                else { allregion += regionSelected[keyy].value + ',' }
            }
        }
        if (districtSelected.length == 0) { alldistrict = "0" }
        else {
            for (var keyyy in districtSelected) {
                if (districtSelected.length == parseInt(keyyy + 1)) { alldistrict += districtSelected[keyyy].value; }
                else { alldistrict += districtSelected[keyyy].value + ',' }
            }
        }
        if (centretypeSelected.length == 0) { allcentreytpe = "0" }
        else {
            for (var keyyyy in centretypeSelected) {
                if (centretypeSelected.length == parseInt(keyyyy + 1)) { allcentreytpe += centretypeSelected[keyyyy].value; }
                else { allcentreytpe += centretypeSelected[keyyyy].value + ',' }
            }
        }
        if (centreSelected.length == 0) { allcentre = "0" }
        else {
            for (var keyyyyy in centreSelected) {
                if (centreSelected.length == parseInt(keyyyyy + 1)) { allcentre += centreSelected[keyyyyy].value; }
                else { allcentre += centreSelected[keyyyyy].value + ',' }
            }
        } setIsLoading(true);
        let url = URL_Start + 'master/getdashboardbannerdetails/' + allstate + '/' + allregion + '/' + alldistrict + '/' + allcentreytpe + '/' + allcentre + '/' + startdate + '/' + enddate
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data != null) {
                    setBannerDetails(data.Data);
                }
                else {
                    setBannerDetails([]);
                }
            }
            else {
                setBannerDetails([]);
                // SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); 
            }
        }).catch(function (error) { if (error.response) { setIsLoading(false); ErrorFunction(error.response.status); } });
    }
    const SaveDashboardBannerDetails = async (e) => {
        var BannerName = "";
        var Banner = "";
        var Thumbnail = "";
        if (editicon === false) {
            if (stateSelected.length === 0) { SweetAlert.fire("State is required"); return false; }
            if (regionSelected.length === 0) { SweetAlert.fire("Region is required"); return false; }
            if (districtSelected.length === 0) { SweetAlert.fire("District is required"); return false; }
            if (centretypeSelected.length === 0) { SweetAlert.fire("Partner type is required"); return false; }
            if (centreSelected.length === 0) { SweetAlert.fire("Centre is required"); return false; }
        }
        if (stardate == undefined || stardate == null || stardate == "") {
            SweetAlert.fire("Start date is required"); return false;
        }
        if (endate == undefined || endate == null || endate == "") {
            SweetAlert.fire("End date is required"); return false;
        }
        BannerName = (urlbanneref.current.value == null || urlbanneref.current.value == undefined) ? "" : urlbanneref.current.value;
        Banner = bannerPath.substring(bannerPath.lastIndexOf("/") + 1, bannerPath.length);
        Thumbnail = thumbnailPath.substring(thumbnailPath.lastIndexOf("/") + 1, thumbnailPath.length);
        // if ((BannerName == '' || BannerName == null || BannerName == undefined) && (Banner == '' || Banner == null || Banner == undefined)) { SweetAlert.fire("Banner text and banner attachment both cannot be blank. Please select any one."); return false; }
        // if (BannerName != '' && Banner != '') { SweetAlert.fire("You cannot enter banner text and attachment both. Please enter any one."); return false; }
        if ((BannerName == '' || BannerName == null || BannerName == undefined) && (Banner == '' || Banner == null || Banner == undefined) && (Thumbnail == '' || Thumbnail == null || Thumbnail == undefined)) { SweetAlert.fire("Banner text, thumbnail attachment and banner attachment are madatory "); return false; }
        if (BannerName == '' || BannerName == null || BannerName == undefined) { SweetAlert.fire("Banner text is madatory "); return false; }
        if (Banner == '' || Banner == null || Banner == undefined) { SweetAlert.fire("Banner attachment is madatory "); return false; }
        if (Thumbnail == '' || Thumbnail == null || Thumbnail == undefined) { SweetAlert.fire("Thumbnail attachment is madatory "); return false; }
        setIsSaving(true);
        let arryCentres = [];
        for (var i in centreSelected) { arryCentres.push({ 'CentreId': centreSelected[i].value, 'CentreName': centreSelected[i].label }); }
        const requestOptions = {
            BannerName: BannerName,
            Banner: Banner,
            Thumbnail: Thumbnail,
            Centres: arryCentres,
            Flag: editicon === true ? "1" : "0",
            AddedBy: localStorage.LoggedInUser,
            StartDate: startdate.current.value,
            EndDate: enddate.current.value
        };
        let url = URL_Start + 'master/savedashboardbannerdetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setIsSaving(false);
                editicon === true ? SweetAlert.fire("Dashboard banner details updated successfully") : SweetAlert.fire("Dashboard banner details saved successfully")
                setStateSelected([]);
                setRegionSelected([]);
                setDistrictSelected([]);
                setCentreSelected([]);
                SetLocationRegionList([]);
                SetLocationDistrictList([]);
                setVirtualCentre([]);
                setVirtualCentreType([]);
                setBannerPath('');
                setThumbnailPath('');
                var element = document.querySelector("input[type='file']")
                element.classList.remove("removeValue");
                document.getElementById("urlbanner").value = "";
                document.getElementById("urlthumbnail").value = "";
                urlbanneref.current.value = "";
                setEditIcon(false);
                GetDashboardBannerDetails();
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const EditBannerDetails = async (rowData) => {
        setEditIcon(true);
        setStateName(rowData.StateName);
        setRegionName(rowData.RegionName);
        setDistrictName(rowData.DistrictName);
        setCentreName(rowData.CentreName);
        setCentreType(rowData.CentreType);
        setStartDate((rowData.StartDate == null || rowData.StartDate == undefined) ? "" : rowData.StartDate.split(" ")[0]);
        setEndDate((rowData.EndDate == null || rowData.EndDate == undefined) ? "" : rowData.EndDate.split(" ")[0]);
        var arr = [];
        arr.push({
            value: rowData.CentreBannersId,
            label: rowData.CentreName
        })
        if (rowData.Banner == "" || rowData.Banner == undefined || rowData.Banner == null) { setBannerPath(''); }
        else { setBannerPath(Image_Path + "DashboardBanners/" + rowData.Banner); }
        if (rowData.Thumbnail == "" || rowData.Thumbnail == undefined || rowData.Thumbnail == null) { setThumbnailPath(''); }
        else { setThumbnailPath(Image_Path + "ThumbnailBanners/" + rowData.Thumbnail); }
        setCentreSelected(arr);
        setUrlBannerRef(rowData.BannerName);
    }
    const DeleteBanner = async (CentreBannersId) => {
        SweetAlert.fire({
            title: 'Warning',
            text: "You are going to delete an item. Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            cancelButtonColor: "#000",
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) { ConfirmedDeleteBanner(CentreBannersId); }
                else { SweetAlert.fire('Your data is safe!') }
            })
    }
    const ConfirmedDeleteBanner = async (CentreBannersId) => {
        //setIsSaving(true);
        const requestOptions = {
            CentreBannerId: CentreBannersId,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/deletedashboardbanner'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({
                    text: "Deleted",
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) { GetDashboardBannerDetails(); clearHandler(); }
                        else { GetDashboardBannerDetails(); clearHandler(); }
                    })
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const clearHandler = async () => {
        setEditIcon(false);
        setStateSelected([]);
        setRegionSelected([]);
        setDistrictSelected([]);
        setCentreSelected([]);
        SetLocationRegionList([]);
        SetLocationDistrictList([]);
        setVirtualCentre([]);
        setVirtualCentreType([]);
        setBannerPath('');
        setThumbnailPath('');
        setStartDate('');
        setEndDate('');
        document.getElementById("urlbannereff").value = "";
        setClearFunction(true);
        //GetDashboardBannerDetails();
    }
    const cancelHandler = async () => {
        setEditIcon(false);
        setStateSelected([]);
        setRegionSelected([]);
        setDistrictSelected([]);
        setCentreSelected([]);
        SetLocationRegionList([]);
        SetLocationDistrictList([]);
        setVirtualCentre([]);
        setVirtualCentreType([]);
        setBannerPath('');
        setThumbnailPath('');
        setStartDate('');
        setEndDate('');
        document.getElementById("urlbannereff").value = "";
        var element = document.querySelector("input[type='file']")
        element.classList.remove("removeValue");
        document.getElementById("urlbanner").value = "";
        document.getElementById("urlthumbnail").value = "";
        setClearFunction(true);
        //GetDashboardBannerDetails();
    }
    const StartDateHandler = (e) => {
        if (e.target.value == null || e.target.value == undefined) { setStartDate(''); setEndDate(''); }
        else {
            setStartDate(e.target.value);
            const today = new Date(e.target.value);
            const date = today.setDate(today.getDate() + 7);
            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
            setEndDate(defaultValue);
        }
    }
    const EndDateHandler = (e) => {
        if (e.target.value == null || e.target.value == undefined) { setEndDate(''); }
        else { setEndDate(e.target.value); }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Breadcrumb title="Dashboard Banner Master" parent="Master" />
            <Container fluid>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Dashboard Banner Details</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        {
                                            editicon === true ?
                                                <>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>SAP State<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" value={statename} disabled />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>SAP Region<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" value={regionname} disabled />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>SAP District<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" value={districtname} disabled />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Partner Type<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" value={centretype} disabled />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Centre<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" value={centrename} disabled />
                                                        </Form.Group>
                                                    </Col>
                                                </> :
                                                <>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>SAP State<span className='text-required'>*</span></Form.Label>
                                                            <Select options={[{ label: "All", value: "0" }, ...locationstatelist]} isMulti={true} onChange={StateChangeHandler}
                                                                value={stateSelected} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>SAP Region<span className='text-required'>*</span></Form.Label>
                                                            <Select options={[{ label: "All", value: "0" }, ...locationregionlist]} isMulti={true} onChange={RegionChangeHandler}
                                                                value={regionSelected} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>SAP District<span className='text-required'>*</span></Form.Label>
                                                            <Select options={[{ label: "All", value: "0" }, ...locationdistrictlist]} isMulti={true} onChange={DistrictChangeHandler}
                                                                value={districtSelected} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Partner Type<span className='text-required'>*</span></Form.Label>
                                                            <Select options={[{ label: "All", value: "0" }, ...virtualcentretype]} isMulti={true} onChange={CentreTypeChangeHandler}
                                                                value={centretypeSelected} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Centre<span className='text-required'>*</span></Form.Label>
                                                            <Select options={[{ label: "All", value: "0" }, ...virtualcentre]} isMulti={true} onChange={CentreChangeHandler}
                                                                value={centreSelected} />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                        }
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Start date<span className='text-required'>*</span></Form.Label>
                                                <Form.Control type="date" name="startdate" value={stardate} ref={startdate} onChange={StartDateHandler} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>End date<span className='text-required'>*</span></Form.Label>
                                                <Form.Control type="date" name="enddate" value={endate} ref={enddate} onChange={EndDateHandler} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Banner Text<span className='text-required'> (Max 20 characters allowed)*</span></Form.Label>
                                                <Form.Control type="text" name="urlbanneref" ref={urlbanneref} id="urlbannereff" maxLength={20} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Thumbnail Attachment<span className='text-required'> (Max size upto 2 mb accepted in dimensions 660 height x 395 width)*</span></Form.Label>
                                                <div><input type="file" id="urlthumbnail" onChange={(e) => UploadImages(e, "ThumbnailBanners")} accept="image/jpeg,image/gif,image/png" />
                                                    {
                                                        thumbnailPath == "" ? null : <img src={thumbnailPath} width="80"></img>
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Banner Attachment<span className='text-required'> (Max size upto 5 mb accepted)*</span></Form.Label>
                                                <div><input type="file" id="urlbanner" onChange={(e) => UploadImages(e, "DashboardBanners")} accept="image/jpeg,image/gif,image/png,application/pdf" />
                                                    {
                                                        bannerPath == "" ? null :
                                                            <>
                                                                {
                                                                    bannerPath.substring(bannerPath.lastIndexOf('.') + 1) == "pdf" ? <span style={{ fontSize: 24 }}><i class="fa fa-file-pdf-o"></i></span> : <img src={bannerPath} width="80"></img>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Col md={12} className='text-center'>
                                        <Form.Group>
                                            {
                                                editicon == true ? null :
                                                    <>
                                                        {isLoadingButton ? <LoadingButton className="mr-2" variantName='primary' cssName="" /> : <Button className="mr-2" variant='primary' onClick={(e) => GetDashboardBannerDetails()}>Search</Button>}
                                                    </>
                                            }
                                            <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                            {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' onClick={(e) => SaveDashboardBannerDetails(e)}>Save</Button>}
                                        </Form.Group>
                                    </Col>
                                    <Form.Row>
                                        <Col md={12}>
                                            {
                                                isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                    <DataTables keyField='CentreBannersId' tableData={bannerdetails} columns={columns} pagination={paginationFactory(paginationOptions)} noDataIndication={NoRecordFounds} />
                                            }
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}

export default DashboardBanners