import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DynamicDataTable from '../commonComponent/DataTable/DynamicDataTable';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import InputGroup from 'react-bootstrap/InputGroup';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
import { Spinner } from 'react-bootstrap';
const ServiceWiseCollections = () => {
    let fromDate = useRef();
    let toDate = useRef();
    // const [table, setTable] = useState([]);
    const [tableData, setTableData] = useState({
        PageNo: 1,
        PageSize: 100,
        TotalRecord: 0,
        Rows: []

    });
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    const [SelectCentreDDL, SetSelectCentreDDL] = useState([]);
    const [SelectCentres, SetSelectCentres] = useState([]);
    const [LGlCentreTypeoption, SetLGlCentreTypeoption] = useState([]);
    const [filtertype, setFilterType] = useState("1");
    const [SelectBusinessUNTDDL, SetSelectBusinessUNTDDL] = useState([]);
    const [SelectBusinessType, SetSelectBusinessType] = useState([]);
    const [LGlSelectBusUNTDDLTypeoption, SetLGlSelectBusUNTDDLTypeoption] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetBusinessList();
        // GetBindCenterAllLists("1");
        BindGrid(defaultValue, defaultValue, tableData.PageNo, tableData.PageSize,"1");
    }, []);
    const paginationOptions = {
        totalSize: tableData.TotalRecord,
        sizePerPage: tableData.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); BindGrid(fromDate.current.value, toDate.current.value, page, sizePerPage,filtertype); },
        onSizePerPageChange: (sizePerPage, page, event) => { BindGrid(fromDate.current.value, toDate.current.value, page, sizePerPage,filtertype); document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show'); },
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'ClientCode', text: 'Client Code', editable: false },
    { dataField: 'ClientName', text: 'Client Name', editable: false },
    { dataField: 'PartnerType', text: 'Partner Type', editable: false },
    { dataField: 'VisitingCode', text: 'Visiting Code', editable: false },
    { dataField: 'OrderNo', text: 'Order No', editable: false },
    { dataField: 'OrderDate', text: 'Order Date', editable: false },
    { dataField: 'BillNo', text: 'Bill No', editable: false },
    { dataField: 'BillDate', text: 'Bill Date', editable: false },
    { dataField: 'PatientName', text: 'Name', editable: false },
    { dataField: 'MobileNo', text: 'Mobile No', editable: false },
    { dataField: 'ItemName', text: 'Item Name', editable: false },
    { dataField: 'TestCode', text: 'Test Code', editable: false },
    { dataField: 'SAPCode', text: 'SAP Code', editable: false },
    { dataField: 'GrossAmount', text: 'Gross Amount', editable: false },
    { dataField: 'Discount', text: 'Discount', editable: false },
    { dataField: 'DiscountRemark', text: 'DiscountRemark', editable: false },
    { dataField: 'NetAmount', text: 'NetAmount', editable: false },
    { dataField: 'PaymentStatus', text: 'Payment Status', editable: false },
    { dataField: 'Panel', text: 'Panel', editable: false },
    { dataField: 'RatePlan', text: 'RatePlan', editable: false },
    { dataField: 'Source', text: 'Source', editable: false }
    ];
    const BindGrid = async (fromDateSelected, toDateSelected, PageNo, PageSize,filtertype) => {
        setIsLoading(true);
        const ObjServiceCollection = {
            UserId: localStorage.getItem('LoggedInUser'),
            FromDate: fromDateSelected,
            ToDate: toDateSelected,
            objtblCentreIds: LGlCentreTypeoption,
            PageNo: `${PageNo}`,
            PageSize: `${PageSize}`,
            IsExcelD: `${0}`,
            FilterType: filtertype
        };
        try {
            let url = URL_Start + 'reports/GetServicewiseCollectionReport'
            await axios.post(url, ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        setIsLoading(false);
                        if (data.Data.SerViceList != null && data.Data.SerViceList.length > 0) {
                            setTableData({
                                ...tableData,
                                PageSize: Number(PageSize),
                                TotalRecord: Number(data.Data.TotalRecord),
                                Rows: data.Data.SerViceList
                            });
                        }
                        else {
                            setTableData({
                                PageNo: 1,
                                PageSize: 100,
                                TotalRecord: 0,
                                Rows: []

                            });
                        }

                        //setTable(data.Data);
                    }
                    else {
                        setTableData({
                            PageNo: 1,
                            PageSize: 100,
                            TotalRecord: 0,
                            Rows: []

                        });
                    }
                }
                else {
                    setTableData({
                        PageNo: 1,
                        PageSize: 100,
                        TotalRecord: 0,
                        Rows: []

                    });
                }
            }).catch(function (error) {
                setTableData({
                    PageNo: 1,
                    PageSize: 100,
                    TotalRecord: 0,
                    Rows: []

                });

            });
        }
        catch (error) {
            setTableData({
                PageNo: 1,
                PageSize: 100,
                TotalRecord: 0,
                Rows: []

            });

            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
            setIsLoading(false);
        }
    }
    const GetReportData = async (fromDateSelected, toDateSelected, PageNo, PageSize,filtertype) => {
        setIsLoading(true);
        setDownloadVisible(false);
        const ObjServiceCollection = {
            UserId: localStorage.getItem('LoggedInUser'),
            FromDate: fromDateSelected,
            ToDate: toDateSelected,
            objtblCentreIds: LGlCentreTypeoption,
            PageNo: `${PageNo}`,
            PageSize: `${PageSize}`,
            IsExcelD: `${1}`,
            FilterType: filtertype
        };
        try {
            let url = URL_Start + 'reports/GetServicewiseCollectionReport'
            await axios.post(url, ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null && data.Data.SerViceList.length > 0) {
                        setDownloadVisible(true);
                        setCSVData(data.Data.SerViceList);
                        document.getElementById("csvdownload").click();
                    }
                    else { setCSVData([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        setIsLoading(false);
    }
    const handleSearch = async () => {
        setCrntPageNO(0);
        BindGrid(fromDate.current.value, toDate.current.value, tableData.PageNo, tableData.PageSize,filtertype);
    }
    const handleDownload = async () => {
        setCrntPageNO(0);
        GetReportData(fromDate.current.value, toDate.current.value, tableData.PageNo, tableData.PageSize,filtertype);
    }
    const GetBindCenterAllLists = async (ftypevalue, LGlSelectBusUNTDDLTypeoption) => {
        const ObjFilterCentye = [];
        const requestOptions = {
            CentreFilterType: ftypevalue,
            objtblTagBusinessIds:LGlSelectBusUNTDDLTypeoption
        };
        try {
            let url = URL_Start + 'master/getBindAllCentreListsBussLabNew'
            await axios.post(url, requestOptions).then((response) => { //ObjFilterCentye
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        let ds = data.Data;
                        const TypeOptionDs1 = [];
                        ds.map((option) => {
                            const TypeOptions1 = {
                                'CentreId': option.CentreId,
                                'label': option.CentreName,
                                'value': option.CentreId,
                                'CenterTypeId': option.CenterTypeId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectCentreDDL(TypeOptionDs1);

                    }
                    else { SetSelectCentreDDL([]); }
                }
            }).catch(function (error) {
                //ErrorFunction(error.response.status);
                SetSelectCentreDDL([]);
            });
        }
        catch (error) {
            SetSelectCentreDDL([]);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const CentreHandler = (options) => {
        SetSelectCentres(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'CentreId': item.CentreId
                }
                return TypeOptL.push(TypeOptrows);
            });
            SetLGlCentreTypeoption(TypeOptL);
        }
        else {
            SetLGlCentreTypeoption([]);
        }
    }
    const AdvanceChkHandler = (e) => {
        SetSelectCentreDDL([]);
        SetSelectCentres([]);
        setFilterType(e.target.value);
        GetBindCenterAllLists(e.target.value, LGlSelectBusUNTDDLTypeoption);
    }

    const GetBusinessList = async () => {

        try {
            let url = URL_Start + 'master/getlabslist/2'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        let ds=data.Data;
                        const TypeOptionDs1 = [];
                       ds.map((option) => {
                           const TypeOptions1 = {
                            'CentreId': option.Id,
                            'label': option.Name,
                            'value': option.Id,
                            'disabled': false
                           }
                           return TypeOptionDs1.push(TypeOptions1);
                       });
                       SetSelectBusinessUNTDDL(TypeOptionDs1); 
                      
                   }
                   else {  SetSelectBusinessUNTDDL([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const  BusinessTabHandler = (options) => {
        SetSelectCentreDDL([]);
        SetSelectCentres([]);
        SetSelectBusinessType(options);
            const TypeOptL = [];
            if (options != null && options.length > 0) {
                options.map((item) => {
                    const TypeOptrows = {
                        'TagBusinessId': item.CentreId
                    }
                    return TypeOptL.push(TypeOptrows);
                });
                SetLGlSelectBusUNTDDLTypeoption(TypeOptL);
                GetBindCenterAllLists(filtertype, TypeOptL);
            }
            else{
                SetLGlSelectBusUNTDDLTypeoption([]);
            }
            
        }
    
    return (
        <React.Fragment>
            <Breadcrumb title="Servicewise Collection" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className='justify-content-center mt-4'>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}></Col>
                                </Form.Row>
                                <Form.Row className='justify-content-center mt-4'>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Tag Business Unit</Form.Label>
                                                <div className='txtMultiSelect2'>
                                                <MultiSelect id="ddlbussSelect" name="ddlbussSelect"  
                                                    options={SelectBusinessUNTDDL}
                                                    value={SelectBusinessType}
                                                    onChange={BusinessTabHandler}
                                                    labelledBy="Select" 
                                                    
                                                /></div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}></Col>
                                </Form.Row>
                                <Form.Row className='justify-content-center'>
                                    <Col md={9}>
                                        <div>
                                            <Form.Group className='advancepaymentradio'>
                                                <Form.Label><strong>Filter Data:</strong></Form.Label>
                                                <Form.Label>
                                                    <input name="chkAdvance" defaultChecked={true} type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={1} />All
                                                </Form.Label> &nbsp;  &nbsp;
                                                <Form.Label>
                                                    <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={2} />Own
                                                </Form.Label> &nbsp;   &nbsp;
                                                <Form.Label>
                                                    <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={3} />Third Party
                                                </Form.Label> &nbsp;   &nbsp;
                                                <Form.Label>
                                                    <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={4} />Panel
                                                </Form.Label>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row className='justify-content-center'>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Centre</Form.Label>
                                            <div className='txtMultiSelect1'>
                                                <MultiSelect id="CentreSelect" name="CentreSelect"
                                                    options={SelectCentreDDL}
                                                    value={SelectCentres}
                                                    onChange={CentreHandler}
                                                    labelledBy="Select"
                                                /></div>
                                        </Form.Group>
                                    </Col>

                                    <Col md={3} className='topmargin30'>
                                        <Button variant='secondary' onClick={handleSearch}>Search</Button>
                                        <Button className='ml-2' variant='primary' onClick={handleDownload}>Download CSV</Button>
                                    </Col>
                                </Form.Row>
                                <Form.Row className='justify-content-center mt-4'>
                                    <Col lg={12} md={12}>
                                        {
                                         isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DynamicDataTable
                                                keyField='Id'
                                                tableData={tableData.Rows}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationOptions ? paginationFactory(paginationOptions) : null}
                                            />
                                        </>

                                        }
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CSVData} filename="ServicewiseReport.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default ServiceWiseCollections